.btn-success{
    border-radius: 0px;
}

.btn-warning{
    border-radius: 0px;
}

.btn-primary{
    border-radius: 0px;
}

.card, .card-header, .card-body, .card-footer{
    border-radius:0px !important;
}